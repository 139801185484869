/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

a.typeClientLink:hover {
    background: #efefef;
}

a.disabled {
    color: #b2b2b2 !important;
}

a.disabled:hover {
    color: #e5e5e5 !important;
}